<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-29 17:25:31
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 18:57:50
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\recruit\Recruit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-main home-main">
      <SiteTopbar/>
      <div class="headerss-ss"></div>
    <div class="containers">
      <p>
       <span style="font-size:23px;">深圳市小秤砣科技有限公司</span>（注册地址：中国 广东省 深圳市光明区马田街道合水口社区中粮云景广场第1栋1305）尊重并保护您的隐私。<br><br>

					您使用我们的服务时，我们将按照<span>《图哐哐》</span>隐私政策（以下简称“本政策”）收集、存储、使用及对外提供您的个人信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您信息的服务。本政策与您使用我们的服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。<br>

					访问或使用<span>《图哐哐》</span>，即表示您已阅读，理解并同意受本隐私政策和我们的使用条款的约束。
			
					为了遵守国家法律法规及监管规定（例如：进行实名制管理、互联网信息管理、采取风险防范措施），也为了向您提供服务及提升服务质量（例如：支持我们开发新产品或完善已有产品功能，为您提供和推荐更为优质或适合的服务），我们需要收集、存储、使用及对外提供您的信息。您同意我们按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账号及内容安全。
			<br>

		一、我们如何收集个人信息

					我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在服务过程中主动提供或产生的个人信息，帮助您成为我们的用户，向您提供产品/服务，为您优化用户体验，为您提供安全保障。<br>
					1.帮助您成为我们的用户<br>
					为遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，在您注册成为<span>《图哐哐》</span>用户时，您需要至少提供手机号码和工作单位以创建<span>《图哐哐》</span>账号；如果您使用平台提供的任意功能，则必须注册成为我们的用户及提供上述信息，并登录使用所有功能。<br>
					2.向您提供产品/服务<br>
					（一）我们在您使用服务过程中可能收集您的人个信息，为了满足法律法规及提供服务的基本要求，保障您的账号安全与系统运行安全，我们会收集您在使用我们服务过程中产生的相关信息，包括:<br>
					（1）日志信息。当您使用我们提供的服务时，我们会自动收集您使用我们服务的详细情况，并作为有关的网络日志保存，包括但不限于您的IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、及您请求的网页记录等信息。<br>
					（2）
					设备信息。使用<span>《图哐哐》</span>创建、编辑、浏览模型，因设备适配会影响操作速度及完整性等，我们可能会根据您在软件安装及使用中的具体权限，接收并记录您在接受我们服务过程中使用的相关设备信息，例如设备型号、唯一设备标识符、操作系统、分辨率、电信运营商等软硬件信息等。<br>
					（3） 地理位置信息：从您的浏览器或设备收到的Internet协议（IP）地址可用于确定大概位置并改善我们向您提供的服务。<br>
					（4） 相机相册授权：您使用<span>《图哐哐》</span> APP进行扫码登录时，需要访问您的相机相册，以便能够顺利使用<span>《图哐哐》</span>。<br>
					(5) 使用内部数据：这是有关如何使用<span>《图哐哐》</span>服务的各种功能的信息。我们将使用这些信息来更好地了解和改善我们提供给您的服务质量。<br>
					对于从您使用相同<span>《图哐哐》</span>账号登录各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。<br>
					（二）各项权限说明：<br>
					（1）手机存储权限。为给您提供浏览、上传、保存三维文件服务，我们可能会申请手机存储权限。<br>
					（2） 摄像头、麦克风、相册权限。为给您提供扫一扫功能，头像更换，聊天协同等功能，我们可能会申请摄像头和麦克风权限。<br>
					（3） 电话权限。为给您提供APP内直接播打电话的功能，我们可能会申请电话权限。<br>
					（4） 定位权限。为给您提供文件传输功能，我们可能会申请定位权限，仅用于获取WiFi信息，不会收集精确位置。<br>
					（5） 手机通讯录权限。为给您提供添加通讯录好友功能，我们可能会申请手机通讯录权限。<br>
					（6） 设备信息和是志信息。在查看模型时，为保障服务所必须，我们可能会收集设备信息与日志信息用于推送消息和统计。<br>
					（7） 使用行为信息。为了展示更适合您的内容和服务，我们会综合统计和通过算法做特征偏好分析，形成间接人群画像，用以向您进行推荐相关信息。为保障推荐质量，我们可能会收集使用行为信息（例如：点击、点赞、收藏、下载、模型操作时长等在APP的相关操作信息）。<br>
					

					（三）我们限制在以下情况下共享我们收集和接收到的信息。<br>
					（1） 账户支持：为了向您提供客户支持，我们的工作人员可能需要相互共享您以前提供给我们的信息。<br>
					（2） 法律合规性和公共保护：当我们认为出于遵守法律，法规，法律要求或公共机构的合法要求或保护公众和任何人的安全、权利和财产，而合理必要的披露时，我们将共享您的信息。<br>
					（3）<span>《图哐哐》</span>服务的完整性：我们将酌情共享您的信息，以检测、预防和解决欺诈、安全或技术问题，或以其他方式保护<span>《图哐哐》</span>及其用户社区。<br>
					（4）共享文档：如果您共享到另一个<span>《图哐哐》</span>用户公开的文档中，则查看该文档的任何其他用户将可以看到您的某些个人身份信息。<br>

					3.我们可能间接收集到您的个人信息。<br>
					在您使用过程中，因为使用<span>《图哐哐》</span>形成的数据统计，并与之相关的服务改进中涉及的个人信息，尽量避免搜集，但不限于将整体统计数据作为业务分析、融资上市、宣传推广使用。<br>
					为维护、改进我们的产品或服务质量，向您提供个性化的服务，我们可能会在符合法律规定并根据您具体的授权情况下收集并使用如下信息：<br>
					（1） 我们可能会收集您对软件的使用信息、行为偏好（使用时间、使用时长、分享渠道）、
					以及将您在<span>《图哐哐》</span>使用某项服务中提供的信息与来自其他服务中的信息结合起来，进行综合统计、分析以形成用户画像，用来向您推荐或展示您可能感兴趣的产品/服务信息。
					（2）我们可能会收集您在参与产品/服务调查时主动向我们提供的信息，以及您与我们的关联方、合作伙伴之间互动时提供的相关信息，以便于我们优化客户服务的质量与流程。<br>
					（3）我们可将业务中收集的个人信息用于统计分析和改进运营，例如通过您对<span>《图哐哐》</span>的操作进行统计分析，从而改进我们的产品、服务或营销计划；又如为改进我们系统而进行的软件升级、技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。<br>

					4.根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意：<br>
					（1）国家安全、国防安全直接相关的；<br>
					（2）与公共安全、公共卫生、重大公共利益直接相关的；<br>
					（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；<br>
					（4）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；<br>
					（5）所收集的个人信息是您自行向社会公众公开的；<br>
					（6）从合法公开披露的信息中收集个人信息，例如：合法的新闻频道、政府信息公开等渠道；<br>
					（7）根据您的要求签订和履行合同所必须的；<br>
					（8）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；<br>
					（9）法律法规规定的其他情形。<br>

					5.其他：<br>
					我们在向您提供其他业务功能时，会另行向您说明信息收集的范围与目的，并征得您的同意后收集提供相应服务所必要的您的信息。<br>
					我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，不会影响您使用<span>《图哐哐》</span>服务。<br>
					<br>
					客服：当您与我们的客服取得联系时，我们的系统会记录您与客服之间的通讯记录，以及您的账号信息以便核验身份；对于技术人员协助补充或者转换的模型，在完成工作后立即删除，在征得您同意后，在授权范围内使用模型或文档用于宣传或其他用途，客服与您的通讯记录可视为有效授权记录<br>

			<br>
			二、我们如何使用Cookie、Beacon、Proxy等技术
	
					为使您获得更轻松的访问体验，您访问<span>《图哐哐》</span>提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者用来判断您的账户安全状态。这些数据文件可能是Cookie，Flash
					Cookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。<br>
					请您理解，我们的某些服务只能通过Cookie才能得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝<span>《图哐哐》</span>的Cookie。多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。<br>
					此外，您可以通过改变浏览器附加程序设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据（例如：Flash
					Cookie）。但这举动在某些情况下可能会影响您安全访问<span>《图哐哐》</span>。<br>

					我们APP上还可能包含一些电子图像（以下简称“单像素GIF文件”或“网络Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您APP操作活动的信息，例如：您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定<br>

          <br>
				三、我们如何存储和保护个人信
					（1）我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。如部分产品涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们会按照法律法规和相关监管部门的规定执行，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。<br>
					（2）为保障您的信息安全，我们致力于使用各种安全技术有配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。同时我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用权限原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。<br>
					（3）我们已制定个人信息安全事件应急预案，使相关责任人掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关的情况以发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。<br>
					（4）请您务必妥善保管好您的<span>《图哐哐》</span>登录名及其他身份要素。您在使用<span>《图哐哐》</span>服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。如您发现<span>《图哐哐》</span>登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。<br>
					（5）在您终止使用<span>《图哐哐》</span>服务后，我们会停止对您信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有您的个人信息进行删除或匿名处理。<br>
					（6）随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或娄似的交易，如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新征求您的授权周意。<br>
					（7）您在使用我们服务时自愿发布、公开分享的信息，可能会涉及您或他人的知识产权、商业机密甚至个人敏感信息，如您的分享模型，以及您在评价时选择上传包含知识产权、商业机密甚至个人敏感信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。<br>
					（8）对于您主动公开的模型、文档及相关信息，在授权范围内其他用户可以浏览或有限制的使用，<span>《图哐哐》</span>不承担传播后所涉及的法律问题，如若您自已不慎发布，可向<span>《图哐哐》</span>管理员举证产权所有，请求管理员协助删除或停止传播，<span>《图哐哐》</span>将尽可能协助删除，但无法保证完全删除或阻止传播。<br>
				<br>
				四、我们如何使用个人信息<br>

					（一）为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和内容安全，我们会在以下情形中使用您的信息：<br>
					（1） 实现本政策中“我们如何收集个人信息”所述目的；<br>
					（2） 邀请您参与我们产品或服务有关的客户调研；<br>
					（3） 您通信：我们会对您的信息或您对<span>《图哐哐》</span>服务的使用情况进行综合统计、分析
					加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计产品、服务及运营活动等。<br>
					（4） 供<span>《图哐哐》</span>服务：我们使用您提供的信息对您进行身份验证，并确保您能够使用
					<span>《图哐哐》</span>协作、存储和其他相关服务。<br>
					（5） 合规性和调查：为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安
					全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。如发现有违法
					活动，我们将申请相关部门进行调查。<br>
					（6） 我们可能会通过使用Cookie等技术来访问您使用APP的信息，收集点击数据，为您量身定制内容和个性化的体验。这些信息也有利于改善我们的内部运营，并通知用户我们服务的更新。<br>
					（7）
					关于使用<span>《图哐哐》</span>服务时生成的专有设计数据和其他材料，我们会将这些信息作为维护、保护、解决技术问题的参考内容，进一步修改、提高<span>《图哐哐》</span>体验效果。<br>
					（二）注册成功后，如果有恶意行为，我们有权注销或删除您的<span>《图哐哐》</span>账户。<br>
				<br>

				五.文档/建立模型的安全保护
				
					您可以在<span>《图哐哐》</span>中公开/私密您的文档（或建模），并且您可以与其他用户共享公开/私密文档（或建模）。若您在<span>《图哐哐》</span>中建立公开模型，即表示您同意其他人对其的可访问性，您公开的任何文件均不属于私有和机密。同时，<span>《图哐哐》</span>绝不会公开您的私密内容<br>
					您还可以随时将自已创建的文档或模型从公开重新分类为私密，但<span>《图哐哐》</span>不能保证您过去任何时候公开的任何文件的机密性。<br>
					当您在<span>《图哐哐》</span>中共享文档或模型时，与您共享文档的任何人都可以下载使用、复制、操纵、分发、显示、传输和传达该文档的内容。如果您不希望其他人拥有这些权限，请不要在<span>《图哐哐》</span>中共享您的信息，也不要设置相应的权限。<br>

<br>
			  六.未成年人的个人个息保护

					<span>《图哐哐》</span>是一款专业的工程技术软件，我们的APP和服务主要面向成人。如您为未成年人，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。<br>

					对于经父母或监护人同意使用我们产品或服务而收集未成年人个人信息的情况，我们会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。<br>
					如果我们发现自已在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
				<br>
        七.通知和修订
					为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新。也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。<br>
				<br>

				八.本政策的适用及更新<br>
					<span>《图哐哐》</span>所有服务均适用本政策。<br>
					发生下列重大变化情形时，我们会适时对本政策进行更新：<br>
					（1） 我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；<br>
					（2） 收集、存储、使用个人信息的范围、目的、规则发生变化；<br>
					（3） 对外提供人个信息的对象、范围、目的发生变化；<br>
					（4） 您访问和管理个人信息的方式发生变化；<br>
					（5） 数据安全能力、信息安全风险发生变化；<br>
					（6） 用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；<br>
					（7） 其他可能对您的个人信息权益产生重大影响的变化。<br>
					由于<span>《图哐哐》</span>的用户较多，如本政策发生更新，我们将以发送邮件/短消息或者在<span>《图哐哐》</span>官方媒体上发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用<span>《图哐哐》</span>服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。<br>

				<br>

			九.如何联系我们

					如果您对于我们的个人信息处理行为存在任何投诉举报需求，您可以通过<span>《图哐哐》</span>提供的联系方式/客服系统与我们取得联系并作出充分描述，我们将在验证您身份的30天内答复您的请求并尽力解决。<br>
					如果您对我们的回复不满意，特别是认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼。<br>
</p>
					<div class="cont-text">版权所有：深圳市小秤砣科技有限公司----版本V1.0</div>


    </div>
    <Footer/>
    <Info/>
  </div>
</template>

<script>
import SiteTopbar from '@/components/header/SiteTopbar'
import Footer from '@/components/PageMain/Bottom/Footer'
import Info from '@/components/PageMain/Bottom/Info'

export default {
  name: "recruit",
  components: {
  SiteTopbar,
    Footer,
    Info
  },
   data(){
    return{
      isOverWX: false
    }
  },
  methods: {
   
    brief(){
     
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
    created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')

      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  mounted(){
this.brief()
  }
 
};
</script>

<style>
.home-main {
  /* padding-top: 4px; */
  /* padding-bottom: 12px; */
  /* background: rgba(235, 236, 237, 1); */

}
.page-main {
  background: #f5f5f5;
}
.containers {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  

  background: #ffffff;
  border-radius: 15px;
  position: relative;
  top: -100px;
  padding: 60px 60px 100px 60px;

  

}
.containers p{
	
color: rgba(69, 77, 89, 1);
font-size: 18px;
line-height: 40px;
font-weight: 400;
}
.headerss-ss{
  width: 100%;
  background-image: url('../../assets/imgs/TKK/yin.png');
  background-repeat:no-repeat;
  height: 390px;
  background-size: 100%;
}
.cont-text{
	margin-top: 30px;
	font-size: 20px;
	text-align: center;
	color: #636b79;

}

</style>